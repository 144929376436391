.login {
  height: 100vh;
  width: 100vw;
}

.login .container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.login form {
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 2rem;
  min-width: 15%;
}

.login-header {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #212529;
}

.login-header label {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
}

.login input {
  padding: 0.75rem;
  margin: 0.5rem;
  border-radius: 99999px;
  outline: none;
  border: none;
  background: #e9ecef;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
}
.login input:focus {
  outline: #fca311 solid 1px;
}

.login button {
  padding: 0.75rem;
  margin: 0.5rem;
  background-color: #212529;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 99999px;
  cursor: pointer;
}
