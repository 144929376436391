.live-exam {
  min-height: 100vh;
  max-width: 100%;
}

.live-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: auto;
}

.in-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #dee2e6;
  padding-inline: 2rem;
}

.in-container button {
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: 1rem;
  padding: 0.7rem;
  padding-inline: 0.9rem;
  background: #20bf55;
  border: none;
  border-radius: 0.25rem;
  color: #e9ecef;
}
.in-container button:disabled {
  background: #adb5bd;
  cursor: not-allowed;
}

.live-question {
  padding: 1rem;
  padding-top: 0;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
}

.input-fd {
  padding: 0.75rem;
  margin: 0.5rem;
  border-radius: 99999px;
  outline: none;
  border: none;
  background: #e9ecef;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
}

.show-score {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

