/* .home {
  width: 100vw;
} */

.home-continer {
  display: flex;
  justify-content: center;
}

.gen form {
  display: flex;
  flex-direction: column;
}

.gen input {
  padding: 0.7rem;
  margin: 0.5rem;
  outline: none;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
}

.gen button {
  padding: 0.5rem;
}

.gen select {
  padding: 0.7rem;
  margin: 0.5rem;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
}

.code {
  padding: 0.25rem;
  background: green;
  color: white;
}
