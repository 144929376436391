/* .courses {
  display: flex;
  justify-content: space-between;
  text-align: center;
} */

.courses aside {
  background: #dee2e6;
  overflow-x: hidden;
  padding: 1.25rem;
  border-radius: 0.5rem;
  width: 100%;
}
aside header {
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  text-align: center;
}

.courses .new {
  padding: 0.75rem;
  margin: 0.5rem;
  background-color: #274c77;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 99999px;
  cursor: pointer;
}

.courses .new:hover {
  background: #3a6ea5;
  color: #dee2e6;
  cursor: pointer;
}

.course {
  padding: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  background: #495057;
  margin: 0.125rem;
  margin-top: 1rem;
  border-radius: 1rem;
  color: #dee2e6;
}

/* .course:hover {
  background: #6c757d;
}

.selected {
  background: #004e98;
}

.selected:hover {
  background: #004e98;
}

main {
  margin-left: 3rem;
}

main li {
  width: 90%;
  min-height: 4rem;
  margin-bottom: 1rem;
}
main .question {
  width: 100%;
  padding: 1rem;
  background: #e5e5e5;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
main .selected-question {
  background: #004e98;
}

main .answers {
  display: flex;
  justify-content: space-evenly;
  justify-items: start;
} */

.answers .is-corect {
  color: green;
}

main header {
  display: flex;
}

.add-new-course {
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: column;
}

.add-new-course input {
  padding: 0.75rem;
  margin: 0.5rem;
  border-radius: 99999px;
  /* outline: none; */
  border: none;
  background: #e9ecef;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
}

.add-new-course select {
  padding: 0.75rem;
  margin: 0.5rem;
  border-radius: 99999px;
  /* outline: none; */
  border: none;
  background: #e9ecef;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
  margin: auto;
}
