
.courses-nav {
  margin: 0.5rem;
  overflow: hidden;
  background-color: #212529;
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  text-align: center;
}


.courses-nav a {
  float: left;
  color: #f8f9fa;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.courses-nav a:hover {
  background-color: #495057;
}

.courses-nav a.active {
  background-color: #035235;
}
