.exam {
  height: 100vh;
  /* width: 100vw; */
}

.exam .containerExam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.exam .containerForm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.exam .containerExam form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 2rem;
  min-width: 15%;
}

.exam .containerExam label {
  color: #ba181b;
}

.exam .containerExam input {
  padding: 0.75rem;
  border-radius: 99999px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none;
  border: none;
  background: #e9ecef;
  line-height: 1.125rem;
}

.login input:focus {
  outline: #fca311 solid 1px;
}

.exam .containerExam button {
  padding: 0.75rem;
  background-color: #212529;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 99999px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

