/* .students-container {
  width: 100vw;
} */

/* .con {
  display: flex;
  justify-content: center;
} */

.students {
  border-collapse: collapse;
}

.students td,
.students th {
  border: 1px solid #ddd;
  padding: 8px;
}

.students tr:nth-child(even) {
  background-color: #f2f2f2;
}

.students tr:hover {
  background-color: #ddd;
}

.students th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #04aa6d;
  color: white;
}
